import { ProductVersionColumns } from '@/enums/global/ProductVersionColumns';

// Generate XML content with dynamic column widths
function generateXML(data: any[], ignoreColumns: string[] = []) {
    // Step 1: Calculate maximum width for each column
    const columnWidths = Object.keys(data[0]).reduce((widths, key) => {
        if (!ignoreColumns.includes(key)) {
            const maxLength = Math.max(
                50, // Minimum width in points
                ...data.map((row) => String(row[key] || '').length * 6) // Approximate width per character
            );
            widths[key] = maxLength;
        }
        return widths;
    }, {} as Record<string, number>);

    let xmlContent = `<?xml version="1.0"?>
    <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
            xmlns:o="urn:schemas-microsoft-com:office:office"
            xmlns:x="urn:schemas-microsoft-com:office:excel"
            xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet">
    <Styles>
        <Style ss:ID="headerStyle">
            <Font ss:Bold="1" ss:Size="12"/>
        </Style>
    </Styles>  
    <Worksheet ss:Name="Sheet1">
        <Table>`;

    // Step 2: Add column widths to XML
    Object.keys(data[0]).forEach((key) => {
        if (!ignoreColumns.includes(key)) {
            const width = columnWidths[key] || 150;
            xmlContent += `<Column ss:Width="${width}"/>`;
        }
    });

    // Step 3: Add table headers
    xmlContent += `<Row>`;
    Object.keys(data[0]).forEach((key) => {
        if (!ignoreColumns.includes(key)) {
            xmlContent += `<Cell ss:StyleID="headerStyle"><Data ss:Type="String">${
                ProductVersionColumns[key as keyof typeof ProductVersionColumns]
            }</Data></Cell>`;
        }
    });
    xmlContent += `</Row>`;

    // Step 4: Add data rows
    data.forEach((item) => {
        xmlContent += `<Row>`;
        Object.entries(item).forEach(([key, value]) => {
            if (!ignoreColumns.includes(key)) {
                xmlContent += `<Cell><Data ss:Type="String">${value}</Data></Cell>`;
            }
        });
        xmlContent += `</Row>`;
    });

    xmlContent += `</Table>
    </Worksheet>
    </Workbook>`;

    return xmlContent;
}

// Create download link for Excel file
function downloadExcel(xmlContent: string, fileName: string) {
    const blob = new Blob([xmlContent], { type: 'application/vnd.ms-excel' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.xls`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

// Generate XML and trigger download
export function downloadExcelFile(data: any[], fileName: string = 'products', ignoreColumns: string[] = []) {
    const xmlContent = generateXML(data, ignoreColumns);
    downloadExcel(xmlContent, fileName);
}
