
import { Component, Prop, Vue } from 'vue-property-decorator';
// import { test } from '@/interfaces/components/test';

@Component({
    name: 'ProductImage',
})
export default class ProductImage extends Vue {
    @Prop({ default: false }) private readonly image!: any;
}
