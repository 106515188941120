
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { ProductVersionTabs } from '@/enums/global/ProductVersionTabs';

@Component({
    name: 'ProductVersionsTable',
})
export default class ProductVersionsTable extends Vue {
    @Prop({ required: true }) private config!: Record<string, any>;
    @Prop({ required: false }) private filteredData!: Record<string, any>;
    @Prop({ required: false }) private entity!: string;

    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private get columns() {
        return this.config.columns;
    }

    private get filteredTableData() {
        if (this.filteredData.length) {
            return this.filteredData;
        }

        return null;
    }

    private get tableData() {
        // if (this.entity === 'Product Materials') {
        //     return this.products;
        // }

        return this.config.tableData(); // this is a function that returns the data
    }

    private customRow(record: any) {
        return {
            on: {
                click: () => this.handleRowClick(record),
            },
        };
    }

    private products = [
        {
            key: '160922124909301101200015C',
            name: 'ELSOM9102004',
            commodityGroup: '23 - Pribor - Elektronika',
            matchCode: 'Doza za nadometno vgradnjo podometnega stikala',
            priceOne: 8.2,
            priceTwo: 9,
            productGroup: 'Ceniki-Doplačila',
            updatedAt: '2024-11-04T20:40:26+00:00',
            images: [
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/b32c519e58a91597517bf96e54b461b7b1a8c84f.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/b82f365e9987c424f16572d5c66ecc30639d0a97.png',
            ],
        },
        {
            key: '141114123341000901200015F',
            name: 'HEZA01001SIVAHR',
            commodityGroup: '15 - Vodila HERO',
            matchCode: 'Zaključna letev Krpan Hero - Siva mat',
            priceOne: 9.1,
            priceTwo: 10.9,
            productGroup: 'Ceniki-Doplačila',
            updatedAt: '2024-11-04T20:40:33+00:00',
            images: [
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/90f2c644d3749a7f8619e6386b48135914d76394.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/80f986b9fcda6cbdce49d308fd5e95d3e37a7eb7.pdf',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/4dc6979c7ed7151a72919dc8880f9346a616f537.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/3f0e96efa83aa92a1c40037b260a04ce8af0ae93.pdf',
            ],
        },
        {
            key: '140807124705500901200013N',
            name: 'HEZA010019006ST',
            commodityGroup: '15 - Vodila HERO',
            matchCode: 'Zaključna letev Krpan Hero - Srebrna ST',
            priceOne: 9.1,
            priceTwo: 10.9,
            productGroup: 'Ceniki-Doplačila',
            updatedAt: '2024-11-04T20:40:33+00:00',
            images: [
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/ce145322ff748541806fffa78de526d545dfd13e.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/0c875902de59a7cce9535b3cb2a891ac0ebdd693.pdf',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/6921005ed7706ac804de7792f5a0be25a6011560.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/9ceccfd544213e6260a7cd4d97cd0fee1942d97d.pdf',
            ],
        },
        {
            key: '140807121642900901200004W',
            name: 'HEZA010017016HR',
            commodityGroup: '15 - Vodila HERO',
            matchCode: 'Zaključna letev Krpan Hero - Ral 7016 mat',
            priceOne: 9.1,
            priceTwo: 10.9,
            productGroup: 'Ceniki-Doplačila',
            updatedAt: '2024-11-04T20:40:33+00:00',
            images: [
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/7428483ff1a9d5620cd3f914d30438e55184be90.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/a142f36be5971ed5aa074ef1b0dcb730dd861e46.pdf',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/3156ab725bed8c61269721681e562ead10ec1b7a.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/5e05d722413d4b0bbe119a64689d80c2170020f0.pdf',
            ],
        },
        {
            key: '140807121847900901200007C',
            name: 'HEZA010019007HR',
            commodityGroup: '15 - Vodila HERO',
            matchCode: 'Zaključna letev Krpan Hero - Ral 9007 mat',
            priceOne: 9.4,
            priceTwo: 11.3,
            productGroup: 'Ceniki-Doplačila',
            updatedAt: '2024-11-04T20:40:33+00:00',
            images: [
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/33a498b13571a6770bf110d6e8c40546173b4ccc.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/ee72742c597da32638a0cd7913e8431899d7f5b3.png',
                'https://staging-fs-roltek.s3.eu-central-1.amazonaws.com/images/82b0d387d9290f0350528d794be81bf119602fbd.png',
            ],
        },
    ];

    private handleRowClick(row: any) {
        if (this.entity !== ProductVersionTabs.PRODUCT_MATERIALS) {
            return;
        }

        this.$emit('rowClick', row);
    }

    private async mounted() {
        this.loadingOverlay.start();

        try {
            await this.config.entityFetchEndpoint();
        } catch {
            this.$notification.error({
                message: this.$t('Error'),
                description: this.$t('An error occurred while fetching product versions'),
            });
        } finally {
            this.loadingOverlay.stop();
        }
    }
}
