export enum ProductVersionColumns {
    checklistVersion = 'Checklist Version',
    directory = 'Directory',
    name = 'Name',
    priceListVersion = 'Pricelist Version',
    updatedAt = 'Updated At',
    hasError = 'Has Error',
    commodityGroup = 'Commodity Group',
    matchCode = 'Matchcode',
    priceOne = 'Price 1',
    priceTwo = 'Price 2',
    productGroup = 'Product Group',
}
