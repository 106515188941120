
import { Component, Prop, Vue } from 'vue-property-decorator';
import ProductImage from '@/components/views/CmsIndex/ProductImage.vue';

@Component({
    name: 'ProductImages',
    components: {
        ProductImage,
    },
})
export default class ProductImages extends Vue {
    @Prop({ default: false }) private readonly selectedEntity!: any;
    @Prop({ default: false }) private readonly isImagesModalVisible!: boolean;

    private closeImagesModal() {
        this.$emit('closeImagesModal');
    }

    private get images() {
        return this.selectedEntity?.images;
    }
}
